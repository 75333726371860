<template>
  <div style="background-color: #f8f8fd; min-height: 100vh">
    <back-nav class="title_top" :icon="'img/backb.png'" :textColor="'#111'" :backColor="'none'" />
    <div class="title_top" style="background-color: #f8f8fd">
      <div class="padding-lr-sm margin-bottom-xl">
        <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="fetchData"> -->
        <div
          class="drawLog_item display_flex justify-content_flex-justify align-items_center"
          v-for="(item, index) in listData"
          :key="index"
          style="background-color: rgba(255, 255, 255, 1); box-shadow: none"
        >
          <div class="drawLog_red display_flex align-items_center">
            <div class="margin-left-xs" style="padding-left: 10px">
              <p style="color: rgba(0, 0, 0, 1); font-size: 15px">
                {{ "支付宝：" + item.cheques_number }}
              </p>
              <span style="color: rgba(0, 0, 0, 0.6)">{{
                item.created_at
              }}</span>
            </div>
          </div>
          <div class="drawLog_right">
            <!-- <img style="margin-right: 6px;width: 20px;height: 20px;" src="/img/money_icon.png" alt=""> -->
            <p style="color: rgba(0, 0, 0, 1)">
              提现：￥{{ item.apply_amount }}
            </p>
            <p
              v-if="item.status == 0"
              style="color: #ffef35; text-align: right; margin-top: 4px"
            >
              申请中
            </p>
            <p
              v-if="item.status == 1"
              style="color: #73f59c; text-align: right; margin-top: 5px"
            >
              已通过
            </p>
            <p
              v-if="item.status == 2"
              style="color: #ff5a5a; text-align: right; margin-top: 5px;display: flex;align-items: center;justify-content: flex-end;"
            >
              拒绝
              <img src="img/detail.png" alt="" @click="showDetail(item)">
            </p>
          </div>
        </div>
        <!-- </van-list> -->
        <div class="noData margin-top-xl" v-if="noData">
          <img src="img/noData.png" alt="" />
        </div>
      </div>
    </div>
    <van-popup v-model="showRemark" :style="{ height: '100px',width:'300px',lineHeight:'20px',padding:'10px' }">{{remark}}</van-popup>
  </div>
</template>
  
<script>
import "@/assets/css/base.css";
import "@/assets/css/index.css";
import "@/assets/css/game.css";
import { withdrawRecord } from "@/api/api";
import BackNav from "@/components/backNav.vue";
export default {
  name: "withdrawRecord",
  components: {
    BackNav,
  },
  data() {
    return {
      listData: [],
      noData: false,
      from: "",
      showRemark: false,
      remark:''

    };
  },
  created() {
    let access_token = this.$route.query.access_token;
    localStorage.setItem("access_token", access_token);
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      withdrawRecord()
        .then(
          (response) => {
            // console.log(response)
            this.listData = response.data;
            // 数据全部加载完成

            if (this.listData.length == 0) {
              this.noData = true;
            }
          },
          (error) => {
            console.log(error);
            this.$toast.fail(error);
          }
        )
        .catch((error) => {
          this.$toast.fail(error);
        });
    },
    showDetail(item){
      this.showRemark = true;
      this.remark = item.remark;
    },
    whyNo(remark) {
      this.$toast.fail(remark);
    },
    closePage() {
      if (this.from == "h5") {
        this.$router.go(-1);
      } else {
        let params = "调用";
        this.$bridge.callhandler("closePage", params, (data) => {
          // 处理返回数据
        });
      }
    },
  },
};
</script>
<style>
.van-toast__text {
  white-space: normal;
  word-break: break-all;
}
</style>